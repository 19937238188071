import { Dialog, Transition } from '@headlessui/react'
import { XMarkIcon } from '@heroicons/react/24/outline'
import { Fragment } from 'react'
import Button from './Button'

export interface ConfirmationOptions {
  variant: `danger` | `info`
  title: string
  description: string
}

interface ConfirmationAlertProps extends ConfirmationOptions {
  open: boolean
  onSubmit: () => void
  onClose: () => void
  onExited: () => void
}

export default function ConfirmationAlert({
  open,
  onSubmit,
  onClose,
  onExited,
  variant,
  title,
  description,
}: ConfirmationAlertProps) {
  return (
    <Transition.Root show={open} as={Fragment} afterLeave={onExited}>
      <Dialog as="div" className="relative z-50" onClose={onClose}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-75"
          leave="ease-in duration-200"
          leaveFrom="opacity-75"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-xl bg-white text-left shadow-elevation-4 transition-all sm:w-full sm:max-w-lg">
                <div className="sm:flex sm:items-start">
                  <div className="w-full text-center sm:text-left">
                    <Dialog.Title as="h3" className="my-6 px-7 py-2 text-heading-md text-trn-900">
                      <div className="flex justify-between">
                        {title}
                        <XMarkIcon className="relative -top-[10px] h-5 w-5 cursor-pointer" onClick={onClose} />
                      </div>
                    </Dialog.Title>
                    <div>
                      <p className="whitespace-pre-wrap border-y-2 border-y-trn-200 px-7 py-6 text-ui-sm text-trn-800">
                        {description}
                      </p>
                    </div>
                  </div>
                </div>
                <div className="flex justify-end space-x-4 px-7 py-6">
                  <Button
                    label="Cancel"
                    mode="white"
                    size="md"
                    className="justify-center rounded-full bg-trn-50 px-6 py-3.5 text-trn-900 shadow-elevation-1"
                    onClick={onClose}
                  />
                  <Button
                    type="button"
                    label="Confirm"
                    mode={variant === `danger` ? `danger` : `primary`}
                    size="md"
                    className="justify-center rounded-full px-6 py-3.5"
                    onClick={onSubmit}
                  />
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  )
}
